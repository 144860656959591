import React from 'react';
import cx from 'classnames';
import styles from './Button.module.css';
import Link from 'next/link';
import { VanDeKlokButtonLinkProperties } from './ButtonInterfaces';

export const VanDeKlokButtonLink = ({
  children,
  reverse = false,
  theme,
  target,
  ...rest
}: VanDeKlokButtonLinkProperties) => (
  <Link className={getClassNames(theme, reverse)} target={target} {...rest}>
    <span className={styles.inner}>{children}</span>
    <ButtonArrow />
  </Link>
);

const getClassNames = (theme: VanDeKlokButtonLinkProperties['theme'], reverse: boolean) =>
  cx(styles.sharedStyles, styles.vanDeKlok, {
    [styles.outline]: theme === 'outline',
    [styles.outlineInverted]: theme === 'outlineInverted',
    [styles.reverse]: reverse,
    [styles.orange]: theme === 'orange',
  });

const ButtonArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 42"
    preserveAspectRatio="xMinYMin meet"
    strokeWidth={1}>
    <path d="M0 .5h-.5v41h13.965c.908 0 1.796-.269 2.549-.772a4.51 4.51 0 0 0 1.676-2.054l6.483-16a4.452 4.452 0 0 0 0-3.348l-6.483-16a4.51 4.51 0 0 0-1.676-2.054A4.589 4.589 0 0 0 13.464.5H0Z" />
  </svg>
);
